import { LiteralUnion } from 'models';
import { makeAPICall, makeMockAPICall } from './api';
import config from 'config';

export enum GroupsEnum {
  'Preliminary Forecast',
  'Fire Danger',
  'Fuel State',
  'Fire Behaviour',
  'Weather',
  'Weather Alerts and Warnings',
  'Remote Sensing',
  'Base Maps',
  'Boundaries',
  'Feature Layers',
}

export enum LayerEnum {
  'OSM',
  'NationalBaseMap',
  'World_Bathymetry_Imagery',
  'FireManagementDistricts',
  'FireWeatherAreas',
  'SubAreas',
  'LocalGovernmentAreas',
  'Jurisdiction',
  'Incidents',
  'WeatherStations',
  'IDZ10144_AUS_AFDRS_chaines_95_SFC',
  'IDZ10143_AUS_AFDRS_chaines_flag_SFC',
  'IDZ10148_AUS_FSE_curing_SFC',
  'IDZ10161_AUS_FSE_fuel_type_SFC',
  'IDZ10130_AUS_AFDRS_fdr_prelim_SFC',
  'IDZ10133_AUS_AFDRS_max_fbi_prelim_SFC',
  'IDZ10131_AUS_AFDRS_max_fdr_prelim_SFC',
  'IDZ10132_AUS_AFDRS_fbi_prelim_SFC',
  'IDZ10145_AUS_AFDRS_awap_upper_soil_moisture_SFC',
  'IDZ71000_AUS_T_SFC',
  'IDZ71001_AUS_Td_SFC',
  'IDZ71002_AUS_MaxT_SFC',
  'IDZ71003_AUS_MinT_SFC',
  'IDZ71030_AUS_DailyPrecip10Pct_SFC',
  'IDZ71014_AUS_DailyPrecip25Pct_SFC',
  'IDZ71015_AUS_DailyPrecip50Pct_SFC',
  'IDZ71016_AUS_DailyPrecip75Pct_SFC',
  'IDZ71018_AUS_RH_SFC',
  'IDZ71071_AUS_WindMagKmh_SFC',
  'IDZ71089_AUS_Wind_Dir_SFC',
  'IDZ71109_AUS_MixHgt_SFC',
  'IDZ71072_AUS_WindGustKmh_SFC',
  'IDZ71110_AUS_WindMagKmh_1500mAMSL',
  'IDZ71111_AUS_Wind_Dir_1500mAMSL',
  'IDZ71114_AUS_LAL2_SFC',
  'IDZ71115_AUS_CHaines_SFC',
  'IDZ71127_AUS_DF_SFC',
  'IDZ10159_AUS_FSE_grass_fuel_load_SFC',
  'IDZ71147_AUS_KBDI_SFC',
  'IDZ71235_AUS_SDI_SFC',
  'IDZ10147_AUS_AFDRS_wcdi_flag_SFC',
  'IDZ10146_AUS_AFDRS_wcdi_SFC',
  'IDZ10139_AUS_AFDRS_intensity_SFC',
  'IDZ10137_AUS_AFDRS_max_fbi_SFC',
  'IDZ10136_AUS_AFDRS_max_fdr_SFC',
  'IDZ10142_AUS_AFDRS_spotting_distance_SFC',
  'IDZ10141_AUS_AFDRS_flame_height_SFC',
  'IDZ10138_AUS_AFDRS_ros_SFC',
  'IDZ10135_AUS_AFDRS_fbi_SFC',
  'IDZ10134_AUS_AFDRS_fdr_SFC',
  'IDZ10162_AUS_FSE_time_since_fire_SFC',
  'IDZ10149_AUS_FSE_grass_condition_SFC',
  'IDE00431',
  'IDE00433',
  'IDE00435',
  'IDR00010',
  'IDZ20005',
  'IDZ20006',
  'IDZ20012000',
  'IDZ20012001',
  'IDZ71073_AUS_WindMaxInHourMagKmh_SFC',
  'IDZ71075_AUS_WindOnHourMagKmh_SFC',
  'fire_weather_area_fdr_fbi_forecast',
  'sub_area_fdr_fbi_forecast',
}

export declare namespace Layer {
  export type Groups = keyof typeof GroupsEnum;

  export type LayerIds = keyof typeof LayerEnum;

  export type Status = 'normal' | 'prending-update' | 'processing' | 'error' | 'not yet available' | 'unavailable';

  export namespace API {
    export interface LayerSummary {
      product_code: string;
      status: string;
      last_updated: string;
      time_steps: string[];
    }

    export interface Root {
      data: LayerSummary[];
      count: number;
    }
  }
}

export interface Layer {
  id: Layer.LayerIds;
  name: string;
  serviceName: string | null;
  thumbnail?: string;
  summary?: string;
  isPrivileged: boolean;
  hoursPerStep: number;
  isTimeEnabled: boolean;
  isMainLayer: boolean;
  isBaseMap: boolean;
  isBoundary: boolean;
  isUtility: boolean;
  isHidden?: boolean;
  isNotUpdateable?: boolean;
  noLegend?: boolean;
  group: Layer.Groups;
  status: Layer.Status;
  units?: string;
  precision?: number;
  error?: string;
  mvtLabelField?: string;
  childLayer?: Layer.LayerIds;
  lastUpdated: number | null;
  timeSteps: Date[] | null;
  labelLayerServiceName?: string; // the label-only layer name in Geoserver
  labelLayerLabelField?: string; // the column name of the db table to support the label-only layer
}

export interface VectorLayerConfig {
  featureName: string;
}

export interface VectorLayerConfigMap {
  [key: string]: VectorLayerConfig;
}

export const groups = Object.keys(GroupsEnum).filter((x) => Number.isNaN(+x)) as Layer.Groups[];

export const layerIds = Object.keys(LayerEnum).filter((x) => Number.isNaN(+x)) as Layer.LayerIds[];

export const isValidLayer = (id: string): id is Layer.LayerIds => layerIds.indexOf(id as Layer.LayerIds) > -1;

export const layerData: Omit<Layer, 'lastUpdated' | 'timeSteps' | 'status'>[] = [
  {
    id: 'OSM',
    name: 'Open Street Maps',
    serviceName: null,
    thumbnail: '/thumbnails/osm-logo.png',
    summary: 'Open Source Street Map',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: true,
    isBoundary: false,
    isUtility: false,
    group: 'Base Maps',
    isNotUpdateable: true,
  },
  {
    id: 'NationalBaseMap',
    name: 'National Basemap',
    serviceName: 'fse-shared:NationalBaseMap',
    thumbnail: '/thumbnails/ga-national-basemap.png',
    summary: "Geoscience Australia's National Basemap",
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: true,
    isBoundary: false,
    isUtility: false,
    group: 'Base Maps',
    isNotUpdateable: true,
  },
  {
    id: 'World_Bathymetry_Imagery',
    name: 'Satellite Imagery',
    serviceName: 'fse-shared:World_Bathymetry_Imagery',
    thumbnail: '/thumbnails/ga-satellite-basemap.png',
    summary: "Geoscience Australia's Bathymetry Satellite Imagery",
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: true,
    isBoundary: false,
    isUtility: false,
    group: 'Base Maps',
    isNotUpdateable: true,
  },
  {
    id: 'FireManagementDistricts',
    name: 'Fire Management Districts',
    serviceName: 'fse-shared:fire_management_area',
    thumbnail: '/thumbnails/fire-management-area.png',
    summary: 'Fire Management Districts',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: true,
    isUtility: false,
    mvtLabelField: 'fire_management_area_name',
    group: 'Boundaries',
    isNotUpdateable: true,
    labelLayerServiceName: 'fse-shared:fire_management_area_labels',
    labelLayerLabelField: 'label',
  },
  {
    id: 'FireWeatherAreas',
    name: 'Fire Weather Districts',
    serviceName: 'fse-shared:fire_weather_area',
    thumbnail: '/thumbnails/fire-weather-area.png',
    summary: 'Fire Weather Areas',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: true,
    isUtility: false,
    mvtLabelField: 'fire_area_name',
    group: 'Boundaries',
    isNotUpdateable: true,
    labelLayerServiceName: 'fse-shared:fire_weather_area_labels',
    labelLayerLabelField: 'label',
  },
  {
    id: 'SubAreas',
    name: 'Sub-Areas',
    serviceName: 'fse-shared:sub-area',
    thumbnail: '/thumbnails/fire-weather-area.png',
    summary: 'Sub-Areas',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: true,
    isUtility: false,
    mvtLabelField: 'dist_name',
    group: 'Boundaries',
    isNotUpdateable: true,
    labelLayerServiceName: 'fse-shared:sub_area_labels',
    labelLayerLabelField: 'label',
  },
  {
    id: 'LocalGovernmentAreas',
    name: 'Local Government Areas',
    serviceName: 'fse-shared:local_government_area',
    thumbnail: '/thumbnails/fire-weather-area.png',
    summary: 'Local Government Areas',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: true,
    isUtility: false,
    mvtLabelField: 'lga_name',
    group: 'Boundaries',
    isNotUpdateable: true,
    labelLayerServiceName: 'fse-shared:local_government_area_labels',
    labelLayerLabelField: 'label',
  },
  {
    id: 'Jurisdiction',
    name: 'Jurisdictions',
    serviceName: 'fse-tables:jurisdiction',
    thumbnail: '/thumbnails/jurisdictions.png',
    summary: 'Jurisdictional boundaries',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: true,
    isUtility: false,
    group: 'Boundaries',
    isNotUpdateable: true,
  },
  {
    id: 'Incidents',
    name: 'Incidents',
    serviceName: 'fdv-standard:incident',
    thumbnail: '/thumbnails/point-advice.png',
    summary: 'A national incident layer',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: false,
    isUtility: true,
    group: 'Feature Layers',
  },
  {
    id: 'WeatherStations',
    name: 'Weather Stations',
    serviceName: 'fdv-standard:weather_station',
    thumbnail: '/thumbnails/weather-station.png',
    summary: 'Displays the location of Bureau of Meteorology automatic weather stations',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: false,
    isUtility: true,
    group: 'Feature Layers',
  },
  {
    id: 'IDZ10148_AUS_FSE_curing_SFC',
    name: 'Grass Curing',
    serviceName: 'fdv-standard:IDZ10148_AUS_FSE_curing_SFC',
    thumbnail: '/thumbnails/grass-curing.png',
    summary: 'Grass curing as the percentage of dead material',
    precision: 0,
    units: '%',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ10149_AUS_FSE_grass_condition_SFC',
    name: 'Grass Fuel Condition',
    serviceName: 'fdv-standard:IDZ10149_AUS_FSE_grass_condition_SFC',
    thumbnail: '/thumbnails/grass-condition.png',
    summary: 'Whether the fuel condition is natural (3), grazed (2), or eaten out (1).',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ10159_AUS_FSE_grass_fuel_load_SFC',
    name: 'Grass Fuel Load',
    serviceName: 'fdv-standard:IDZ10159_AUS_FSE_grass_fuel_load_SFC',
    thumbnail: '/thumbnails/grass-fuel-load.png',
    summary: 'Grass fuel load',
    precision: 1,
    units: ' t/ha',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ10161_AUS_FSE_fuel_type_SFC',
    name: 'Fuel Type',
    serviceName: 'fdv-standard:IDZ10161_AUS_FSE_fuel_type_SFC',
    thumbnail: '/thumbnails/fuel-type.png',
    summary: 'The fuel type used to calculate fire behaviour and danger in each grid cell',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ10162_AUS_FSE_time_since_fire_SFC',
    name: 'Time Since Fire',
    serviceName: 'fdv-standard:IDZ10162_AUS_FSE_time_since_fire_SFC',
    thumbnail: '/thumbnails/time-since-fire.png',
    summary: 'The number of years since the grid cell was burned',
    units: ' years',
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ71127_AUS_DF_SFC',
    name: 'Drought Factor',
    serviceName: 'fdv-standard:IDZ71127_AUS_DF_SFC',
    thumbnail: '/thumbnails/drought-factor.png',
    summary: 'Drought Factor',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 3,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ71147_AUS_KBDI_SFC',
    name: 'KBDI',
    serviceName: 'fdv-standard:IDZ71147_AUS_KBDI_SFC',
    thumbnail: '/thumbnails/kbdi.png',
    summary: 'Keetch-Byram Drought Index',
    precision: 1,
    units: 'mm',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ71235_AUS_SDI_SFC',
    name: 'SDI',
    serviceName: 'fdv-standard:IDZ71235_AUS_SDI_SFC',
    thumbnail: '/thumbnails/kbdi.png',
    summary: "Mount's Soil Dryness Index",
    precision: 1,
    units: 'mm',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ10145_AUS_AFDRS_awap_upper_soil_moisture_SFC',
    name: 'Soil Moisture Index',
    serviceName: 'fdv-standard:IDZ10145_AUS_AFDRS_awap_upper_soil_moisture_SFC',
    thumbnail: '/thumbnails/soil-moisture.png',
    summary: 'Soil moisture fraction in the top 10 cm of the soil',
    precision: 2,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fuel State',
  },
  {
    id: 'IDZ10138_AUS_AFDRS_ros_SFC',
    name: 'Rate of Spread',
    serviceName: 'fdv-standard:IDZ10138_AUS_AFDRS_ros_SFC',
    thumbnail: '/thumbnails/spread-rate.png',
    summary: 'Rate of spread',
    precision: 0,
    units: 'm/h',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Behaviour',
  },
  {
    id: 'IDZ10139_AUS_AFDRS_intensity_SFC',
    name: 'Fire Intensity',
    serviceName: 'fdv-standard:IDZ10139_AUS_AFDRS_intensity_SFC',
    thumbnail: '/thumbnails/fire-intensity.png',
    summary: 'Byram’s fire-line intensity',
    precision: 0,
    units: 'kW/m',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Behaviour',
  },
  {
    id: 'IDZ10141_AUS_AFDRS_flame_height_SFC',
    name: 'Flame Height',
    serviceName: 'fdv-standard:IDZ10141_AUS_AFDRS_flame_height_SFC',
    thumbnail: '/thumbnails/flame-height.png',
    summary: 'Flame height',
    precision: 1,
    units: 'm',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Behaviour',
  },
  {
    id: 'IDZ10142_AUS_AFDRS_spotting_distance_SFC',
    name: 'Spotting Distance',
    serviceName: 'fdv-standard:IDZ10142_AUS_AFDRS_spotting_distance_SFC',
    thumbnail: '/thumbnails/spotting-distance.png',
    summary: 'Spotting distance, for forest fuel types only.',
    precision: 0,
    units: 'm',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Behaviour',
  },
  {
    id: 'IDZ71003_AUS_MinT_SFC',
    name: 'Min Temperature',
    serviceName: 'fdv-standard:IDZ71003_AUS_MinT_SFC',
    thumbnail: '/thumbnails/temperature.png',
    summary: 'Daily minimum air temperature',
    precision: 1,
    units: '°C',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71002_AUS_MaxT_SFC',
    name: 'Max Temperature',
    serviceName: 'fdv-standard:IDZ71002_AUS_MaxT_SFC',
    thumbnail: '/thumbnails/temperature.png',
    summary: 'Daily maximum air temperature',
    precision: 1,
    units: '°C',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71000_AUS_T_SFC',
    name: 'Temperature',
    serviceName: 'fdv-standard:IDZ71000_AUS_T_SFC',
    thumbnail: '/thumbnails/temperature.png',
    summary: 'Air temperature',
    units: '°C',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71018_AUS_RH_SFC',
    name: 'Relative Humidity',
    serviceName: 'fdv-standard:IDZ71018_AUS_RH_SFC',
    thumbnail: '/thumbnails/relative-humidity.png',
    summary: 'Relative Humidity',
    units: '%',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71001_AUS_Td_SFC',
    name: 'Dew point',
    serviceName: 'fdv-standard:IDZ71001_AUS_Td_SFC',
    thumbnail: '/thumbnails/temperature.png',
    summary: 'Dewpoint Temperature',
    precision: 1,
    units: '°C',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71071_AUS_WindMagKmh_SFC',
    name: 'Wind Speed and Direction (Surface)',
    serviceName: 'fdv-standard:IDZ71071_AUS_WindMagKmh_SFC',
    thumbnail: '/thumbnails/wind-mag.png',
    summary: 'Wind Magnitude at surface level in km/h and Direction',
    precision: 0,
    units: ' km/h',
    childLayer: 'IDZ71089_AUS_Wind_Dir_SFC',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71073_AUS_WindMaxInHourMagKmh_SFC',
    name: 'Wind Max In Hour',
    serviceName: 'fdv-standard:IDZ71073_AUS_WindMaxInHourMagKmh_SFC',
    thumbnail: '/thumbnails/wind-mag.png',
    summary: 'Maximum wind magnitude and direction in the hour at surface level in km/h',
    precision: 0,
    units: ' km/h',
    childLayer: 'IDZ71089_AUS_Wind_Dir_SFC',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71075_AUS_WindOnHourMagKmh_SFC',
    name: 'Wind On Hour',
    serviceName: 'fdv-standard:IDZ71075_AUS_WindOnHourMagKmh_SFC',
    thumbnail: '/thumbnails/wind-mag.png',
    summary: 'Wind magnitude and direction on the hour at surface level in km/h',
    precision: 0,
    units: ' km/h',
    childLayer: 'IDZ71089_AUS_Wind_Dir_SFC',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71089_AUS_Wind_Dir_SFC',
    name: 'Wind Direction',
    serviceName: 'fdv-standard:IDZ71089_AUS_Wind_Dir_SFC',
    thumbnail: '/thumbnails/wind-dir.png',
    summary: 'Wind Direction (Surface)',
    precision: 0,
    units: '°',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isHidden: true,
    group: 'Weather',
  },
  {
    id: 'IDZ71110_AUS_WindMagKmh_1500mAMSL',
    name: 'Wind Speed and Direction (1500m)',
    serviceName: 'fdv-standard:IDZ71110_AUS_WindMagKmh_1500mAMSL',
    thumbnail: '/thumbnails/wind-mag.png',
    summary: 'Wind Magnitude at 1500 m above sea level in km/h and Direction',
    units: ' km/h',
    childLayer: 'IDZ71111_AUS_Wind_Dir_1500mAMSL',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isHidden: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71111_AUS_Wind_Dir_1500mAMSL',
    name: 'Wind Direction 1500m',
    serviceName: 'fdv-standard:IDZ71111_AUS_Wind_Dir_1500mAMSL',
    thumbnail: '/thumbnails/wind-dir.png',
    summary: 'Wind Direction at 1500 m above sea level',
    units: '°',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: false,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isHidden: true,
    group: 'Weather',
  },
  {
    id: 'IDZ71072_AUS_WindGustKmh_SFC',
    name: 'Wind Gust and Direction (Surface)',
    serviceName: 'fdv-standard:IDZ71072_AUS_WindGustKmh_SFC',
    thumbnail: '/thumbnails/wind-mag.png',
    summary: 'Wind Gust at surface level in km/h and Direction',
    precision: 0,
    units: ' km/h',
    childLayer: 'IDZ71089_AUS_Wind_Dir_SFC',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isHidden: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71030_AUS_DailyPrecip10Pct_SFC',
    name: 'Daily Precip 10%',
    serviceName: 'fdv-standard:IDZ71030_AUS_DailyPrecip10Pct_SFC',
    thumbnail: '/thumbnails/rainfall.png',
    summary: 'Daily rainfall, 10% chance of exceeding',
    units: ' mm',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71014_AUS_DailyPrecip25Pct_SFC',
    name: 'Daily Precip 25%',
    serviceName: 'fdv-standard:IDZ71014_AUS_DailyPrecip25Pct_SFC',
    thumbnail: '/thumbnails/rainfall.png',
    summary: 'Daily rainfall, 25% chance of exceeding',
    units: ' mm',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71015_AUS_DailyPrecip50Pct_SFC',
    name: 'Daily Precip 50%',
    serviceName: 'fdv-standard:IDZ71015_AUS_DailyPrecip50Pct_SFC',
    thumbnail: '/thumbnails/rainfall.png',
    summary: 'Daily rainfall, 50% chance of exceeding',
    units: ' mm',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71016_AUS_DailyPrecip75Pct_SFC',
    name: 'Daily Precip 75%',
    serviceName: 'fdv-standard:IDZ71016_AUS_DailyPrecip75Pct_SFC',
    thumbnail: '/thumbnails/rainfall.png',
    summary: 'Daily rainfall, 75% chance of exceeding',
    units: ' mm',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71109_AUS_MixHgt_SFC',
    name: 'Mixing Height',
    serviceName: 'fdv-standard:IDZ71109_AUS_MixHgt_SFC',
    thumbnail: '/thumbnails/mixing-height.png',
    summary: 'Mixing Height',
    precision: 0,
    units: ' m',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ71114_AUS_LAL2_SFC',
    name: 'Lightning Activity Level',
    serviceName: 'fdv-standard:IDZ71114_AUS_LAL2_SFC',
    thumbnail: '/thumbnails/lightning-activity-level.png',
    summary: 'Lightning Activity Level',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 3,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather',
  },
  {
    id: 'IDZ10144_AUS_AFDRS_chaines_95_SFC',
    name: 'CHaines Climate',
    serviceName: 'fdv-standard:IDZ10144_AUS_AFDRS_chaines_95_SFC',
    thumbnail: '/thumbnails/chaines.png',
    summary: '95th percentile CHaines climatology',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ10143_AUS_AFDRS_chaines_flag_SFC',
    name: 'Daily CHaines Weather Alert',
    serviceName: 'fdv-standard:IDZ10143_AUS_AFDRS_chaines_flag_SFC',
    thumbnail: '/thumbnails/chaines.png',
    summary: 'Daily CHaines Weather Alert',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ71115_AUS_CHaines_SFC', // Delayed
    name: 'CHaines',
    serviceName: 'fdv-standard:IDZ71115_AUS_CHaines_SFC',
    thumbnail: '/thumbnails/chaines.png',
    summary: 'Continuous-Haines index',
    precision: 1,
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ10130_AUS_AFDRS_fdr_prelim_SFC',
    name: 'Preliminary Fire Danger Rating',
    serviceName: 'fdv-elevated:IDZ10130_AUS_AFDRS_fdr_prelim_SFC',
    thumbnail: '/thumbnails/fire-ratings.png',
    summary: 'Preliminary fire danger rating. For advanced users only.',
    precision: 0,
    isPrivileged: true,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Preliminary Forecast',
  },
  {
    id: 'IDZ10131_AUS_AFDRS_max_fdr_prelim_SFC',
    name: 'Preliminary Max Daily Fire Danger Rating',
    serviceName: 'fdv-elevated:IDZ10131_AUS_AFDRS_max_fdr_prelim_SFC',
    thumbnail: '/thumbnails/fire-ratings.png',
    summary: 'Preliminary daily maximum fire danger rating. For advanced users only.',
    precision: 0,
    isPrivileged: true,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Preliminary Forecast',
  },
  {
    id: 'IDZ10132_AUS_AFDRS_fbi_prelim_SFC',
    name: 'Preliminary Fire Behaviour Index',
    serviceName: 'fdv-elevated:IDZ10132_AUS_AFDRS_fbi_prelim_SFC',
    thumbnail: '/thumbnails/fire-behaviour.png',
    summary: 'Preliminary fire behaviour index. For advanced users only.',
    isPrivileged: true,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Preliminary Forecast',
  },
  {
    id: 'IDZ10133_AUS_AFDRS_max_fbi_prelim_SFC',
    name: 'Preliminary Max Daily Fire Behaviour Index',
    serviceName: 'fdv-elevated:IDZ10133_AUS_AFDRS_max_fbi_prelim_SFC',
    thumbnail: '/thumbnails/fire-behaviour.png',
    summary: 'Preliminary daily maximum fire behaviour index. For advanced users only.',
    isPrivileged: true,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Preliminary Forecast',
  },
  {
    id: 'IDZ10134_AUS_AFDRS_fdr_SFC',
    name: 'Fire Danger Rating',
    serviceName: 'fdv-standard:IDZ10134_AUS_AFDRS_fdr_SFC',
    thumbnail: '/thumbnails/fire-ratings.png',
    summary: 'Fire danger rating',
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Danger',
  },
  {
    id: 'IDZ10135_AUS_AFDRS_fbi_SFC',
    name: 'Fire Behaviour Index',
    serviceName: 'fdv-standard:IDZ10135_AUS_AFDRS_fbi_SFC',
    thumbnail: '/thumbnails/fire-behaviour.png',
    summary: 'Fire behaviour index.',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 1,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Danger',
  },
  {
    id: 'IDZ10136_AUS_AFDRS_max_fdr_SFC',
    name: 'Max Fire Danger Rating',
    serviceName: 'fdv-standard:IDZ10136_AUS_AFDRS_max_fdr_SFC',
    thumbnail: '/thumbnails/fire-ratings.png',
    summary: 'Daily maximum fire danger rating.',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Danger',
  },
  {
    id: 'IDZ10137_AUS_AFDRS_max_fbi_SFC',
    name: 'Max Fire Behaviour Index',
    serviceName: 'fdv-standard:IDZ10137_AUS_AFDRS_max_fbi_SFC',
    thumbnail: '/thumbnails/fire-behaviour.png',
    summary: 'Daily maximum fire behaviour index.',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Danger',
  },
  {
    id: 'fire_weather_area_fdr_fbi_forecast',
    name: 'Fire Danger Rating - Area',
    serviceName: 'fdv-standard:fire_weather_area_fdr_fbi_forecast',
    thumbnail: '/thumbnails/fire-ratings.png',
    summary: 'Fire danger rating and fire behaviour index summary by area',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Danger',
  },
  {
    id: 'sub_area_fdr_fbi_forecast',
    name: 'Fire Danger Rating - Sub Area',
    serviceName: 'fdv-standard:sub_area_fdr_fbi_forecast',
    thumbnail: '/thumbnails/fire-ratings.png',
    summary: 'Fire danger rating and fire behaviour index summary by sub area',
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Fire Danger',
  },
  {
    id: 'IDZ10146_AUS_AFDRS_wcdi_SFC',
    name: 'Wind Change',
    serviceName: 'fdv-standard:IDZ10146_AUS_AFDRS_wcdi_SFC',
    thumbnail: '/thumbnails/wind-change.png',
    summary: 'Wind change danger index',
    isPrivileged: false,
    precision: 0,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ10147_AUS_AFDRS_wcdi_flag_SFC',
    name: 'Wind Change Weather Alert',
    serviceName: 'fdv-standard:IDZ10147_AUS_AFDRS_wcdi_flag_SFC',
    thumbnail: '/thumbnails/wind-change-red-flag.png',
    summary: 'Significant wind change Weather Alert',
    precision: 0,
    isPrivileged: false,
    hoursPerStep: 24,
    isTimeEnabled: true,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDE00431',
    name: 'Himawari Infrared',
    serviceName: 'fdv-standard:IDE00431',
    thumbnail: '/thumbnails/IDE00431.png',
    summary: 'Himawari infrared at 2km resolution',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Remote Sensing',
  },
  {
    id: 'IDE00433',
    name: 'Himawari Zehr',
    serviceName: 'fdv-standard:IDE00433',
    thumbnail: '/thumbnails/IDE00433.png',
    summary: 'Himawari infrared with Zehr enhancement at 2km resolution',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Remote Sensing',
  },
  {
    id: 'IDE00435',
    name: 'Himawari True Colour',
    serviceName: 'fdv-standard:IDE00435',
    thumbnail: '/thumbnails/IDE00435.png',
    summary: 'Himawari True Colour Day/Night Composite at 1km resolution',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Remote Sensing',
  },
  {
    id: 'IDR00010',
    name: 'Radar Rain Rate',
    serviceName: 'fdv-standard:IDR00010',
    thumbnail: '/thumbnails/rain-rate.png',
    summary: 'Radar rainfall intensity',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Remote Sensing',
  },
  {
    id: 'IDZ20005',
    name: 'Severe Weather Warning',
    serviceName: 'fdv-standard:IDZ20005',
    thumbnail: '/thumbnails/IDZ20006.png',
    summary: 'Severe Weather Warning',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ20006',
    name: 'Severe Thunderstorm Warning',
    serviceName: 'fdv-standard:IDZ20006',
    thumbnail: '/thumbnails/IDZ20006.png',
    summary: 'Severe Thunderstorm Warning',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ20012000',
    name: 'Fire Weather Warning (+0 day)',
    serviceName: 'fdv-standard:IDZ20012000',
    thumbnail: '/thumbnails/IDZ20012000.png',
    summary: 'Todays Fire Weather Warnings',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Weather Alerts and Warnings',
  },
  {
    id: 'IDZ20012001',
    name: 'Fire Weather Warning (+1 day)',
    serviceName: 'fdv-standard:IDZ20012001',
    thumbnail: '/thumbnails/IDZ20012000.png',
    summary: 'Tomorrows Fire Weather Warnings',
    noLegend: true,
    isPrivileged: false,
    hoursPerStep: 0,
    isTimeEnabled: false,
    isMainLayer: true,
    isBaseMap: false,
    isBoundary: false,
    isUtility: false,
    isNotUpdateable: true,
    group: 'Weather Alerts and Warnings',
  },
];

// Define the vector layers and their corresponding featureName to be queried by WMS GetFeatureInfo request
export const vectorLayersToLoad: VectorLayerConfigMap = {
  fire_weather_area_fdr_fbi_forecast: { featureName: 'fdr' },
  sub_area_fdr_fbi_forecast: { featureName: 'fdr' },
};

/** Pre-define those layers that need to toggle labels (keep layer IDs)
 * This is for disabling the label toggle
 */
export const layersWithToggleableLabels = new Set<string>([
  'FireManagementDistricts',
  'FireWeatherAreas',
  'SubAreas',
  'LocalGovernmentAreas',
]);

const timeBetweenCache = 60 * 1000; // Every minute
let layerSummariesCache: Layer.API.LayerSummary[] = [];
let layerSummariesCachePromise: Promise<Layer.API.LayerSummary[]> | null = null;
let layerLastUpdateTime = 0;

export const findLayer = <T extends { id: Layer.LayerIds }>(
  id: LiteralUnion<Layer.LayerIds>,
  layers?: T[] | null,
): T | undefined => (layers != null ? layers.find((l) => l.id === id) : undefined);

export const getLayerSummaries = makeAPICall<Layer.API.LayerSummary[], void, Layer.API.Root>(
  () => ({
    ext: '/fdv/geoserver/summary-info',
  }),
  (root) => root.data,
);

export const getLayers = makeMockAPICall<Layer[], void, null>(
  () => {},
  async (_, payload, state, dispatch) => {
    if (Date.now() - layerLastUpdateTime > timeBetweenCache && state?.auth.status === 'finished') {
      if (layerSummariesCachePromise) {
        layerSummariesCache = await layerSummariesCachePromise;
      } else {
        layerSummariesCachePromise = getLayerSummaries(payload, state, dispatch);
        layerSummariesCache = await layerSummariesCachePromise;
        layerSummariesCachePromise = null;
        layerLastUpdateTime = Date.now();
      }
    }
    const lDat = layerData
      .map((layer) => {
        const layerSummary = layerSummariesCache.find((l) => l.product_code === layer.id) ?? {
          status: 'unavailable',
          last_updated: null,
          time_steps: null,
        };

        const temp = {
          ...layer,
          status: layerSummary.status as Layer.Status,
          lastUpdated: layerSummary.last_updated != null ? new Date(layerSummary.last_updated).getTime() : null,
          timeSteps: layerSummary.time_steps?.map((ts) => new Date(ts)).sort((a, b) => +a - +b) ?? null,
        };

        return temp;
      })
      .filter((l) => l != null);

    return lDat;
  },
  null,
  0,
);

/**
 * Gets the earliest dates from each layer, and returns the earliest date which satisfies all layers.
 * When multiple layers need to be shown (such as the compare tool), and each layer needs to have some data shown,
 * a 'start' time which can display data for all layers is used.
 * The time returned (if any) can then be matched with 'getStepTime' per layer for the earliest layer time which
 * caters for different layer time step sizes (eg: daily vs hourly)
 *
 * @param layers A list of Layer objects where the list may include null's/ undefined's
 * @returns A Date object which matches the latest start time of all the provided layers. Will return null if all layers don't have any timesteps loaded.
 */
export const getLatestLayerStartDate = (layers: (Layer | undefined | null)[] | undefined): Date | null | undefined => {
  let latestLayerStart = null;
  if (layers) {
    for (let i = 0; i < layers.length; i += 1) {
      const layer = layers[i];
      // initially assume layerStart is 1 full year in the future
      if (layer?.isTimeEnabled && layer?.timeSteps) {
        let layerStart = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        for (let j = 0; j < layer.timeSteps?.length; j += 1) {
          if (layer.timeSteps[j] <= layerStart) {
            layerStart = new Date(layer.timeSteps[j].getTime());
          }
        }
        if (!latestLayerStart || layerStart > latestLayerStart) {
          latestLayerStart = layerStart;
        }
      }
    }
  }
  return latestLayerStart;
};

const hourInMs = 1000 * 60 * 60;

// Calculate number of hours after midnight (float)
const calculateHoursFromMidnight = (timeStep: Date): number => {
  return timeStep.getHours() + timeStep.getMinutes() / 60;
};

// Calculate next time step based on input timeStep and hoursPerStep
const calculatedNextTimeStep = (hoursPerStep: number, timeStep: Date): Date => {
  return new Date(timeStep.getTime() + hoursPerStep * hourInMs);
};

// Find index of containing day for input date and input timestep array
const getIndex = (inputDate: Date, timeSteps: Date[], hoursPerStep: number): number => {
  const foundIndex = timeSteps.findIndex((timeStep, index) => {
    /**
     * This will trim the last day of 24hr daily layers (with 8 entries eg. Max FDR - Daily Precip only has 7).
     * The 6am BoM update will include the 8th day forecast layer but with no data
     * The 4pm Bom update will include the 8th day forecast layer with data
     * Hard to track if 8th day data is accurate so we choose to trim instead.
     */
    let trimDailyLayerLastDay = false;
    if (index === timeSteps.length - 1 && hoursPerStep === 24 && timeSteps.length > 7) {
      trimDailyLayerLastDay = true;
    }
    return (
      index < timeSteps.length &&
      inputDate.getTime() >= timeStep.getTime() &&
      inputDate.getTime() < calculatedNextTimeStep(hoursPerStep, timeStep).getTime() &&
      !trimDailyLayerLastDay
    );
  });
  return foundIndex;
};

/**
 * compare raster timestep [0] to current timestep [0] with GMT+(timezone)
 * compare on the day and see if its +1 day, if so correct he selected day by -1
 */
const shouldCorrectSelectedDate = (
  offset: number,
  selectedRasterLayer: any,
  layer: any,
  rasterOffset: number,
  hrsTillMidnight: number,
): boolean => {
  /**
   * * Offset of 4 is used as at most there is a 3 hour offset between AEST and AWST
   * but with daylight savings that can shift, so 4 hours should cover all cases.
   */
  return (
    (offset > 4 &&
      selectedRasterLayer &&
      selectedRasterLayer.timeSteps &&
      selectedRasterLayer.timeSteps[0]?.getDay() > layer.timeSteps[0].getDay()) ||
    (selectedRasterLayer?.timeSteps != null && selectedRasterLayer?.hoursPerStep === 1 && rasterOffset > 20) ||
    (hrsTillMidnight - offset <= 4 &&
      selectedRasterLayer?.timeSteps != null &&
      selectedRasterLayer.hoursPerStep === layer.hoursPerStep &&
      hrsTillMidnight === 24)
  );
};

/**
 * For the following layers (Daily/3 Hourly/Hourly):
 * 1. Calculate the offset of the selected time.
 * 2. Find if the offsetted time is within the layer timesteps and grab the index in the supplied timesteps.
 * 3. Use the layer timestep value found previously
 * Otherwise, return the offset corrected date value.
 * @param layers List of layers to iterate over
 * @param id The id of the layer of interest
 * @param selectedDate The selected date
 * @returns The timestep of a given layer that is closest to by before or equal to the provided date
 */
export const getStepTime = (
  layers: Layer[] | undefined,
  id: Layer.LayerIds,
  selectedDate?: Date | null,
  selectedRasterLayer?: Layer | null,
): Date | null => {
  const layer = layers?.find((l) => l.id === id);

  if (!layer || !selectedDate) {
    return null;
  }

  // eg. layer.timeSteps here is in local time eg. AWST +8:00 for WA
  if (layer.isTimeEnabled && layer.timeSteps) {
    /**
     * Calculate correction value for timestep hours from the layer's timesteps array
     * Eg. timestep hours % hours per step (5.30pm(17.5) % 3 = 2.5)
     */
    const offset =
      layer.timeSteps[0] != null
        ? calculateHoursFromMidnight(layer.timeSteps[0] ?? new Date()) % layer.hoursPerStep
        : 0;

    let rasterOffset = 0;

    if (selectedRasterLayer != null) {
      if (selectedRasterLayer.timeSteps != null) {
        rasterOffset =
          selectedRasterLayer.timeSteps[0] != null
            ? calculateHoursFromMidnight(layer.timeSteps[0] ?? new Date()) % layer.hoursPerStep
            : 0;
      }
    }
    // Calculate the offset corrected date (eg. daily 12:00am with 8hr offset will be 8am)
    let offsetCorrectedDate = new Date(selectedDate?.getTime() + hourInMs * offset);

    let index: number;

    // If layer is daily
    if (layer.hoursPerStep === 24) {
      let midnightTimeSteps = layer.timeSteps.map((timeStep) => new Date(timeStep.getTime() - hourInMs * offset));

      //  Handles daily layers in the map weather popup the same as the generateOffsetsMatrixHelper would.
      const msToHours = 1000 * 60 * 60;
      const nextDayMidnight = new Date(selectedDate.getTime());
      nextDayMidnight.setDate(nextDayMidnight.getDate() + 1);
      nextDayMidnight.setHours(0, 0, 0, 0);
      const hrsTillMidnight = (nextDayMidnight.getTime() - selectedDate.getTime()) / msToHours;

      if (shouldCorrectSelectedDate(offset, selectedRasterLayer, layer, rasterOffset, hrsTillMidnight)) {
        selectedDate.setDate(selectedDate.getDate() - 1);
      }

      /**
       * If there is a difference between the selected date and nearest next date is <4 hrs
       * There is a possibility that the time offset will cause a day change to occur.
       * If so, correct the corresponding midnight time steps using the offset.
       */
      if (hrsTillMidnight < 4 && selectedRasterLayer?.hoursPerStep == 24) {
        midnightTimeSteps = layer.timeSteps.map((timeStep) => {
          const day = new Date(timeStep.getTime() + hourInMs * offset);
          day.setHours(0, 0, 0, 0);
          return day;
        });

        /**
         * If the hoursPerStep is the same for the queried layer as the selected raster layer (which is 24)
         *   Add 1 day to the selected date
         */
        if (layer.hoursPerStep == 24) {
          selectedDate.setDate(selectedDate.getDate() + 1);
        }
      }

      index = getIndex(selectedDate, midnightTimeSteps, layer.hoursPerStep);

      // Return the selected date + 1day for daily layers to account for previous day
      // timezones being pulled forward a day and then having a possible valid timestep
      // for the last day for the 4pm updates after trimming.
      return index !== -1 ? layer.timeSteps[index] : new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000);
    }
    // If layer is 3 hourly return selected date
    if (layer.hoursPerStep === 3) {
      index = getIndex(offsetCorrectedDate, layer.timeSteps, layer.hoursPerStep);
      return index !== -1 ? layer.timeSteps[index] : offsetCorrectedDate;
    }
    // If layer is hourly
    if (layer.hoursPerStep === 1) {
      // TODO: Check if offsetCorrectedDate should be + or - hourInMs * offset depending on daylight savings
      offsetCorrectedDate = new Date(selectedDate?.getTime() + hourInMs * offset);
      index = getIndex(offsetCorrectedDate, layer.timeSteps, layer.hoursPerStep);
      return index !== -1 ? layer.timeSteps[index] : offsetCorrectedDate;
    }
    // If Non-time enabled layer with single starting time and is incorrectly flagged as timeEnabled.
    if (layer.hoursPerStep === 0) {
      return layer.timeSteps?.[0] ?? null;
    }
  }

  return layer.hoursPerStep === 0 ? layer.timeSteps?.[0] || null : selectedDate || null;
};

export const bookmarkFilter = (layers: Layer[]): Layer[] => {
  const bookmarkIdsUnserialised = localStorage.getItem(config.bookmarkedLayersKey);
  if (bookmarkIdsUnserialised == null) return [];
  try {
    const bookmarkIds = JSON.parse(bookmarkIdsUnserialised) as string[];
    return layers.filter((l) => bookmarkIds.indexOf(l.id) > -1);
  } catch (e) {
    return [];
  }
};

export const getBookmarkedLayers = makeMockAPICall<Layer[], void, Layer[]>(
  () => {},
  async (_, payload, state) => {
    return bookmarkFilter(await getLayers(payload, state));
  },
  [],
  0,
);

export const addBookmark = makeMockAPICall<null, { layer: Layer }>(
  ({ layer }) => {
    const bookmarkIdsUnserialised = localStorage.getItem(config.bookmarkedLayersKey);

    const bookmarkIds = bookmarkIdsUnserialised == null ? [] : (JSON.parse(bookmarkIdsUnserialised) as string[]);
    bookmarkIds.push(layer.id);
    localStorage.setItem(config.bookmarkedLayersKey, JSON.stringify(bookmarkIds));
  },
  () => null,
  null,
  0,
);

export const removeBookmark = makeMockAPICall<null, { layer: Layer }>(
  ({ layer }) => {
    const bookmarkIdsUnserialised = localStorage.getItem(config.bookmarkedLayersKey);

    const bookmarkIds = bookmarkIdsUnserialised == null ? [] : (JSON.parse(bookmarkIdsUnserialised) as string[]);
    localStorage.setItem(config.bookmarkedLayersKey, JSON.stringify(bookmarkIds.filter((bm) => bm !== layer.id)));
  },
  () => null,
  null,
  0,
);

export const clearBookmarks = makeMockAPICall<null>(
  () => {
    localStorage.setItem(config.bookmarkedLayersKey, JSON.stringify([]));
  },
  () => null,
  null,
  0,
);
