interface RestartIntervalInterface {
  isPlayingInterval: NodeJS.Timeout | null;
  setisPlayingInterval: (value: React.SetStateAction<NodeJS.Timeout | null>) => void;
  intervalFunction: () => void;
  newPlaybackSpeed: number | undefined;
  playBackSpeeds: number[];
  playbackSpeedIndex: number;
  currentTime: Date | null | undefined;
}

function restartIntervalEventHandler({
  isPlayingInterval,
  setisPlayingInterval,
  intervalFunction,
  newPlaybackSpeed,
  playBackSpeeds,
  playbackSpeedIndex,
  currentTime,
}: RestartIntervalInterface) {
  if (isPlayingInterval != null) {
    clearInterval(isPlayingInterval);
  }
  if (currentTime) {
    const locationPathname = location.pathname;
    const intervalID = setInterval(() => {
      if (locationPathname !== location.pathname) {
        clearInterval(intervalID);
      } else {
        intervalFunction();
      }
    }, 1000 / (newPlaybackSpeed ?? playBackSpeeds[playbackSpeedIndex]));
    setisPlayingInterval(intervalID);
  }
}

export default restartIntervalEventHandler;
