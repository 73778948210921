/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';
import { customColors } from 'theme';

const CustomSlider = withStyles({
  root: {
    width: 300,
  },
  rail: {
    display: 'none',
  },
  track: {
    display: 'none',
  },
  mark: {
    backgroundColor: customColors.neutralLight,
    height: 18,
    width: 10,
    marginTop: -8,
    marginLeft: -5,
    borderRadius: 3,
    '&:hover': { backgroundColor: customColors.neutralDark },
  },
  markActive: {
    opacity: 1,
    backgroundColor: customColors.neutralDark,
  },
  thumb: {
    backgroundColor: customColors.neutralDark,
    height: 30,
    width: 10,
    marginTop: -14,
    marginLeft: -5,
    borderRadius: 5,
    '&:focus, &:hover, &:active': {
      boxShadow: 'none',
    },
  },
  markLabel: {
    marginTop: 12,
    fontWeight: 'bold',
    color: customColors.neutralLight,
  },
  markLabelActive: {
    fontWeight: 'bold',
    color: customColors.neutralDark,
  },
})(Slider);

interface IProps {
  onChange: (value: number) => void;
  initialValue?: number;
}

const OpacitySlider: React.FunctionComponent<IProps> = ({ onChange, initialValue }) => {
  const initValue = initialValue ?? 100;
  const [sliderValue, setSliderValue] = useState(initValue);

  const handleChange = (_: any, n: number | number[]) => {
    setSliderValue(n as number);
    onChange(n as number);
  };

  useEffect(() => {
    // Will make sure whatever the initvalue is set to
    // we won't need to double up anywhere that uses it,
    // as this will call the 'change' the first time it's rendered
    onChange(initValue);
  }, []);

  const marks = new Array(21)
    .fill(0)
    .map((_, i) => i * 5)
    .map((n: number) => ({ value: n, label: n % 10 === 0 ? n.toString() : '' }));

  return (
    <CustomSlider
      defaultValue={initValue}
      value={sliderValue}
      aria-labelledby="discrete-slider"
      valueLabelDisplay="off"
      step={5}
      min={0}
      max={100}
      onChange={handleChange}
      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        // @ts-ignore
        const val = +event.target?.querySelector('input')?.value;
        if (val != null && !Number.isNaN(val)) handleChange(event, val);
      }}
      marks={marks}
    />
  );
};

OpacitySlider.displayName = 'OpacitySlider';
export default OpacitySlider;
