import { LayerManager } from 'models';

interface GenerateOffsetsMatrixInterface {
  layers: LayerManager.Layer[];
}

function generateOffsetsMatrixHelper({ layers }: GenerateOffsetsMatrixInterface) {
  // Check all layers are 24 hour layers
  const checkNonDailyLayer = layers.find((e) => e.hoursPerStep !== 24);

  const msToHours = 1000 * 60 * 60;

  // Don't generate the offsets matrix if any layer is non 24-hour
  if (checkNonDailyLayer !== undefined) return undefined;

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const offsetsMap: { [key: string]: number } = {};

  layers.forEach((layer) => {
    if (layer.timeSteps === null || layer.serviceName === null || layer.timeSteps.length === 0) {
      return undefined;
    }
    let offset = 0;
    const earliestTimeStepDate = new Date(layer.timeSteps[0].getTime());

    const formatter = new Intl.DateTimeFormat('en-AU', {
      timeZone: 'Australia/Sydney',
      day: '2-digit',
    });

    const referenceDay = parseInt(formatter.format(earliestTimeStepDate), 10);
    const localDay = earliestTimeStepDate.getDate();
    const relativeDayOffset = referenceDay - localDay;

    // Fresh Service Ticket #48185
    // If the local day is not same as reference day (ie Sydney), add offset to bring the data to match with reference day
    // For example, if the earliest time in Sydney is on Sunday, but in Western Australia (WA) it is still Saturday,
    // apply an offset to adjust the data forward so that both locations align on the same day.
    if (relativeDayOffset != 0) {
      const nearestNextDayDate = new Date(earliestTimeStepDate);
      nearestNextDayDate.setDate(nearestNextDayDate.getDate() + 1);
      nearestNextDayDate.setHours(0, 0, 0, 0);

      const differenceInHrs = (nearestNextDayDate.getTime() - earliestTimeStepDate.getTime()) / msToHours;

      // Apply an offset if the earliest time is close to midnight (within 4 hours)
      if (layer.timeSteps[0] < startOfToday && differenceInHrs < 4) {
        offset = (nearestNextDayDate.getTime() - layer.timeSteps[0].getTime()) / msToHours;
      } else {
        offset = 0;
      }
    } else {
      // Adjust layer offset to match with the timeslider start datetime.
      // For example, if the layer's time is 11:00 AM, the offset will be -11 to align with the timeslider start for daily layers.
      const startOfEarliestDay = new Date(earliestTimeStepDate);
      startOfEarliestDay.setHours(0, 0, 0, 0);
      const differenceInHrs = (startOfEarliestDay.getTime() - earliestTimeStepDate.getTime()) / msToHours;
      if (differenceInHrs != 0) offset = (startOfEarliestDay.getTime() - layer.timeSteps[0].getTime()) / msToHours;
    }
    offsetsMap[layer.id] = offset;
  });

  return offsetsMap;
}

export default generateOffsetsMatrixHelper;
