/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Drawer, Toolbar, CircularProgress, TextField, Divider } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Compare,
  LocationSearching,
} from '@material-ui/icons';

import {
  Map,
  FloatingMapButton,
  MapOverlay,
  Bookmarks,
  LayerList,
  IncidentsManager,
  ExportManager,
  IncidentPopup,
  Timeslider,
  WeatherPopup,
  WeatherStationPopup,
} from 'components';
import Legend from 'components/map/Legend';

import {
  MapCommand,
  ZoomToBounds,
  ZoomToCoords,
  AddMarkers,
  Resize,
  AddScaleLine,
  MouseCoords,
  AddWmsTileLayer,
  AddWmsMvtLayer,
  ShowOverlay,
  HideOverlay,
  MouseClick,
  GetMapBounds,
  PanOverlay,
} from 'components/map/MapCommands';
import { getUserState, hasGroup } from 'utils';

import { useAppSelector, useAppDispatch, usePrevious } from 'hooks';
import { LayerActions } from 'state/layers';
import { IncidentActions } from 'state/incidents';
import { MeteogramActions } from 'state/meteograms';
import { FuelTypeModelActions } from 'state/fueltypemodels';
import { AnalyticsActions } from 'state/analytics';

import { IncidentManager, LayerManager, WmsManager, ApplicationManager, Common } from 'models';
import config from 'config';
import DetailsPanel from 'components/detailspanel/Panel';
import { Style, Icon } from 'ol/style';
import generateOffsetsMatrixHelper from './fdv_viewer_subcomponents/helper_functions/generate_offsets_map';
import LayerControls from 'components/map/LayerControls';
import { layersWithToggleableLabels } from 'models/layer';
import { Meteograms } from 'models/meteograms';
import { Layer } from 'models/layer';

const initOpacity = 65;

const weatherPopupLayerListHourly: LayerManager.Layer.LayerIds[] = [
  'IDZ71000_AUS_T_SFC',
  'IDZ71018_AUS_RH_SFC',
  'IDZ71071_AUS_WindMagKmh_SFC',
  'IDZ71127_AUS_DF_SFC',
  'IDZ71014_AUS_DailyPrecip25Pct_SFC',
  'IDZ71016_AUS_DailyPrecip75Pct_SFC',
  'IDZ10161_AUS_FSE_fuel_type_SFC',
  'IDZ10134_AUS_AFDRS_fdr_SFC',
  'IDZ10136_AUS_AFDRS_max_fdr_SFC',
];

const weatherPopupLayerListDaily: LayerManager.Layer.LayerIds[] = [
  'IDZ71018_AUS_RH_SFC',
  'IDZ71071_AUS_WindMagKmh_SFC',
  'IDZ10135_AUS_AFDRS_fbi_SFC',
  'IDZ71000_AUS_T_SFC',
  'IDZ71014_AUS_DailyPrecip25Pct_SFC',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    map: {
      width: '100%',
      height: '100%',
    },
    drawer: {
      flexShrink: 0,
    },
    drawerContent: {
      padding: theme.spacing(0),
      display: 'grid',
      gridTemplateRows: '1fr auto',
      overflowY: 'auto',
      height: '100%',
    },
    sidebarfab: {
      color: theme.palette.common.white,
      transform: 'rotate(90deg)',
      width: 'max-content',
      marginTop: 50,
    },
    sidebarfabicon: {
      color: theme.palette.common.white,
    },
    sidebarBtn: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      border: `1px solid ${theme.palette.common.neutralLight}`,
      marginBottom: 2,
      borderRadius: 8,
      color: theme.palette.common.black70,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
      },
    },
    timesliderfab: {
      color: theme.palette.common.grey,
      width: 'max-content',
      backgroundColor: theme.palette.common.black,
    },
    content: {
      flexGrow: 1,
    },
    bookmarkArea: {
      overflowY: 'auto',
      borderBottom: '1px solid lightgrey',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    drawerTransition: {
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })} !important`,
    },
    panel: {
      overflow: 'hidden',
      transition: `${theme.transitions.create('top', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}`,
    },
    compareFAB: {
      borderRadius: 4,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
    layerControls: {
      borderRadius: 4,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
    lonlat: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr auto',
      placeItems: 'center',
      padding: `0px ${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
      paddingTop: 4,
    },
  }),
);

const MIN_SIDEBAR_WIDTH = '365px';

function FDVViewer() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mapRef = useRef<any>(null);
  const { auth, incidents, meteograms, fuelTypeModels } = useAppSelector((state) => state);
  const {
    layers,
    selectedBoundary,
    selectedMainLayer,
    selectedLayers,
    selectedBaseMap,
    selectedTime,
    viewBounds,
    pinnedLocation,
  } = useAppSelector((state) => state.layers);
  const prevPinnedLocation = usePrevious(pinnedLocation);

  const [mapDispatch, setMapDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();
  const [boundaryLayerCommand, setBoundaryLayerCommand] = useState<AddWmsMvtLayer>();
  const [boundaryLabelLayerCommand, setBoundaryLabelLayerCommand] = useState<AddWmsMvtLayer>();
  const [incidentsLayerCommand, setIncidentsLayerCommand] = useState<AddWmsTileLayer>();
  const [weatherStationsLayerCommand, setWeatherStationsLayerCommand] = useState<AddWmsTileLayer>();
  const [rasterLayerCommand, setRasterLayerCommand] = useState<AddWmsTileLayer>();
  const [childLayerCommand, setChildLayerCommand] = useState<AddWmsTileLayer>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mouseClick, setMouseClick] = useState<MouseClick>();
  const [mapBounds, setMapBounds] = useState<GetMapBounds>();

  const getBounds = (): Common.Bounds | null => mapBounds?.getBounds() ?? null;

  const [sentInitialLoad, setSentInitialLoad] = useState(false);

  const selectedRasterLayer = layers.object?.find((l) => l.id === selectedMainLayer);

  const selectedRasterLayerList = selectedRasterLayer ? [selectedRasterLayer] : [];

  const childLayer =
    selectedRasterLayer && selectedRasterLayer.childLayer
      ? layers.object?.find((l) => l.id === selectedRasterLayer.childLayer)
      : null;

  if (childLayer) selectedRasterLayerList.push(childLayer);

  // This mimics the offsets that the time slider would apply to the appropriate layers in order to render the time slider correctly
  const offsetsMap = generateOffsetsMatrixHelper({
    layers: selectedRasterLayerList,
  });

  const [goToIncident, setGoToIncident] = useState<string | null>(null);
  const [goToLatitude, setGoToLatitude] = useState<string>('');
  const [goToLongitude, setGoToLongitude] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(ApplicationManager.Tabs.AllLayers);

  const [drawerOuterOpen, setDrawerOuterOpen] = useState(true);
  const [drawerOuterWidthValue, setDrawerOuterWidth] = useState(22);
  const drawerOuterWidth = `max(${MIN_SIDEBAR_WIDTH}, ${drawerOuterWidthValue}%)`;

  const [panelOpen, setPanelOpen] = useState(false);
  const [clickDetails, setClickDetails] = useState<Record<string, number | string> | null>(null);

  const [selectedWeatherStation, setselectedWeatherStation] = useState<number | null>(null);

  const [timeSliderOpen, setTimeSliderOpen] = useState(selectedRasterLayer?.isTimeEnabled ?? true);
  const [timeSliderLayerDates, setTimeSliderLayerDates] = useState<Record<string, Date | null>>({});
  const [lastExecutedKey, setLastExecutedKey] = useState<string | null>(null);
  const [identifyIncidentsPromiseResults, setIdentifyIncidentsPromiseResults] = useState<IncidentManager.Incident[]>(
    [],
  );
  const [identifyWeatherStationsPromiseResults, setIdentifyWeatherStationsPromiseResults] = useState<
    Meteograms.WeatherStation[]
  >([]);
  const [identifyFireWeatherAreaPromiseResults, setIdentifyFireWeatherAreaPromiseResults] =
    useState<WmsManager.WMS.WMSGetFeatureInfo.Root | null>(null);
  const [meteogramsPromiseResults, setMeteogramsPromiseResults] = useState<Meteograms | null>(null);
  const [openOverlay, setOpenOverlay] = useState<ApplicationManager.Overlays>(ApplicationManager.Overlays.None);
  const prevOverlay = usePrevious(openOverlay) || ApplicationManager.Overlays.None;

  const mapOffset = `max(${MIN_SIDEBAR_WIDTH}, ${drawerOuterWidthValue}%)`;

  const styleMarker = () =>
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: '/map/pin-red.png',
      }),
    });

  const goToLocation = () => {
    if (goToLatitude && goToLongitude && !(Number.isNaN(+goToLatitude) || Number.isNaN(+goToLongitude))) {
      mapDispatch?.dispatch(new ZoomToCoords([+goToLongitude, +goToLatitude]));
      const coords = [+goToLongitude, +goToLatitude];
      let markers = [{ coords, id: 23456, meta: '' }];
      if (coords[0] === 0 && coords[1] === 0) markers = [];
      mapDispatch?.dispatch(new AddMarkers(markers, undefined, styleMarker));
      dispatch(LayerActions.setPinnedLocation(coords));
    }
  };

  const getTimeSliderCurrentDate = (layerId: Layer.LayerIds) => {
    const offsetToApply = offsetsMap !== undefined && offsetsMap.hasOwnProperty(layerId) ? offsetsMap[layerId] : 0;
    const offsetRemovedTimeSliderTime = selectedTime ? new Date(selectedTime.getTime()) : null;
    offsetRemovedTimeSliderTime?.setHours(offsetRemovedTimeSliderTime.getHours() - offsetToApply);

    const layerSelectedTime: Date | undefined =
      LayerManager.getStepTime(layers.object ?? [], layerId, offsetRemovedTimeSliderTime, selectedRasterLayer) ??
      undefined;
    return layerSelectedTime;
  };

  const getWeatherPopupDetailsDaily = (series: Meteograms.Forecast) => {
    const detailsDaily: Record<string, number | string> = {};
    let timeSliderDate: Date | undefined = selectedRasterLayer
      ? getTimeSliderCurrentDate(selectedRasterLayer?.id)
      : undefined;
    if (!timeSliderDate && selectedRasterLayer?.hoursPerStep == 0) {
      // in case of layer like Fuel Type which has no timing per se
      // just use current local date
      timeSliderDate = new Date();
    }

    if (timeSliderDate) {
      // Function to check if a date matches the current day
      const isSameDay = (date: Date) =>
        date.getFullYear() === selectedTime?.getFullYear() &&
        date.getMonth() === selectedTime?.getMonth() &&
        date.getDate() === selectedTime?.getDate();

      // Get forecast for product "IDZ10135_AUS_AFDRS_fbi_SFC"
      const fbiForecast = series.IDZ10135_AUS_AFDRS_fbi_SFC.forecast
        .map(([date, value]) => [new Date(date), value] as [Date, number])
        .filter(([date]) => isSameDay(date));

      // Find max value and its earliest occurrence
      if (fbiForecast.length == 0) return detailsDaily;
      const maxFbiValue = Math.max(...fbiForecast.map(([, value]) => value));

      const fbiMaxEntry = fbiForecast
        .filter(([, value]) => value === maxFbiValue)
        .reduce((earliest, current) => (current[0] < earliest[0] ? current : earliest));
      const fbiMaxDateTime = fbiMaxEntry[0];

      // Create result object with values for all products
      for (const [productId, productData] of Object.entries(series)) {
        const targetLyr = LayerManager.layerData.find((lyr) => lyr.id === productId);
        if (targetLyr && (targetLyr.hoursPerStep == 24 || targetLyr.hoursPerStep == 0)) {
          // Special case: Match day only since no hourly data
          let nonHourlyEntry = productData.forecast
            .map(([date, value]) => [new Date(date), value] as [Date, number])
            .find(([date]) => isSameDay(date));
          if (nonHourlyEntry) {
            detailsDaily[productId] = nonHourlyEntry[1];
          } else if (productData.forecast.length == 1) {
            // fuel type scenario
            nonHourlyEntry = productData.forecast[0];
            detailsDaily[productId] = nonHourlyEntry[1];
          }
        } else {
          // General case: Match fbi_max_date
          const forecastEntry = productData.forecast.find(
            ([date]) => date.getDay() === fbiMaxDateTime.getDay() && date.getHours() === fbiMaxDateTime.getHours(),
          );
          if (forecastEntry) detailsDaily[productId] = forecastEntry[1];
        }
      }
    }
    return detailsDaily;
  };

  const getWeatherPopupDetails = (
    meteogramData: Meteograms | null,
    otherLayerData: (Record<string, string | number> | Record<string, number>)[],
    identifyFireWeatherArea: WmsManager.WMS.WMSGetFeatureInfo.Root | null,
  ): Record<string, number | string> => {
    const isDaily = selectedRasterLayer?.hoursPerStep === 24 || selectedRasterLayer?.hoursPerStep === 0;
    let detailsDaily: Record<string, number | string> = {};
    if (isDaily) {
      // parse out the daily metails from meteograms
      if (meteogramData?.series) detailsDaily = getWeatherPopupDetailsDaily(meteogramData?.series);
    }

    const detailsHourly = otherLayerData.reduce<Record<string, number | string>>((acc, val) => {
      return { ...acc, ...val };
    }, {});

    if (identifyFireWeatherArea?.features[0]?.properties.fire_area_name) {
      detailsHourly.FireWeatherAreas = identifyFireWeatherArea.features[0].properties.fire_area_name;
      // this regex to pull the state from the acc property of the FireWeatherArea
      // e.g. pull "NSW" from "NSW_FW014"
      const jurisdiction = /^([A-Z]+)_[a-z1-9]*/.exec(identifyFireWeatherArea.features[0].properties.aac)?.[1];
      if (jurisdiction) detailsHourly.Jurisdiction = jurisdiction;
    }

    const details = { ...detailsHourly, ...detailsDaily };
    return details;
  };

  const mustQueryMeteograms = (coords: number[]) => {
    // check if single step or daily layer
    if (!(selectedRasterLayer && (selectedRasterLayer.hoursPerStep == 24 || selectedRasterLayer.hoursPerStep == 0)))
      return false;

    // if coords have NOT changed and meteogramsPromiseResults is
    // not null, this can be pulled from the cache (do not re-query)
    if (meteogramsPromiseResults && pinnedLocation && pinnedLocation[1] == coords[1] && pinnedLocation[0] == coords[0])
      return false;

    // requery by default (so as not to display stale data)
    return true;
  };

  const makeOnMouseClick =
    (mapDispatcher: (command: MapCommand) => void, isRerun = false) =>
    async (coords: number[]) => {
      // sometime call to get getLayers lags a little
      if (layers.status !== 'finished') return;

      // set variable to avoid redundant executions
      const uniqueKey = `${selectedTime}-${selectedRasterLayer?.id}-${coords[0]}-${coords[1]}`;
      if (uniqueKey === lastExecutedKey) {
        // Skipping redundant execution, parameters already processed
        return;
      }
      setLastExecutedKey(uniqueKey);

      const markers = [{ coords, id: 12345, meta: '' }];
      mapDispatcher(new AddMarkers(markers, undefined, styleMarker));
      setOpenOverlay(ApplicationManager.Overlays.Loading);

      const bounds = getBounds();
      let bbox: Common.Bounds | null = null;

      if (bounds) {
        const offsetLon = (bounds.maxLong - bounds.minLong) / 60;
        const offsetLat = (bounds.maxLat - bounds.minLat) / 40;
        bbox = {
          minLat: coords[1] - offsetLat,
          minLong: coords[0] - offsetLon,
          maxLat: coords[1] + offsetLat,
          maxLong: coords[0] + offsetLon,
        };
      }

      let identifyIncidentsResults, identifyWeatherStationsResults, identifyFireWeatherAreaResults;

      // only set Pinned Location on new clicks
      dispatch(LayerActions.setPinnedLocation(coords));

      // since when isRerun = true, this always occurs at the same location hence
      // no need to re-query for incidents, weatherStations and FireWeatherAreas
      // fetch from local state
      if (isRerun) {
        // fetch from local state
        identifyIncidentsResults = identifyIncidentsPromiseResults;
        identifyWeatherStationsResults = identifyWeatherStationsPromiseResults;
        identifyFireWeatherAreaResults = identifyFireWeatherAreaPromiseResults;
      } else {
        // fetch via network calls with a promise.all for identify data
        // Define promises
        const identifyIncidentsPromise =
          selectedLayers && selectedLayers.indexOf('Incidents') > -1 && bbox
            ? dispatch(
                IncidentActions.getIncidentsByBBox({
                  bbox,
                }),
              )
            : Promise.resolve([]); // Return an empty array if condition not met

        const identifyWeatherStationsPromise =
          selectedLayers && selectedLayers.indexOf('WeatherStations') > -1 && bbox
            ? dispatch(
                MeteogramActions.getWeatherStationsByBBox({
                  bbox,
                }),
              )
            : Promise.resolve([]); // Return an empty array if condition not met

        const fireWeatherAreas = LayerManager.findLayer('FireWeatherAreas', layers.object);
        const identifyFireWeatherAreaPromise = WmsManager.queryWMS(
          fireWeatherAreas ? [fireWeatherAreas] : [],
          coords,
          auth.object,
          undefined,
        );

        // Run all promises in parallel
        const [incidentsRes, weatherStationsRes, fireWeatherAreasResultsRes] = await Promise.all([
          identifyIncidentsPromise,
          identifyWeatherStationsPromise,
          identifyFireWeatherAreaPromise,
        ]);

        // Assign results
        identifyIncidentsResults = incidentsRes;
        identifyWeatherStationsResults = weatherStationsRes;
        identifyFireWeatherAreaResults = fireWeatherAreasResultsRes;

        // Save results to local state for reruns
        setIdentifyIncidentsPromiseResults(identifyIncidentsResults);
        setIdentifyWeatherStationsPromiseResults(identifyWeatherStationsResults);
        setIdentifyFireWeatherAreaPromiseResults(identifyFireWeatherAreaResults);
      }

      const queryMeteograms = mustQueryMeteograms(coords);
      const meteogramsPromise: Promise<Meteograms | null> = queryMeteograms
        ? dispatch(
            MeteogramActions.getMeteograms({
              coords: coords,
              products: weatherPopupLayerListDaily,
            }),
          )
        : Promise.resolve(null); // Ensures allLayerData has a placeholder

      const layerPromises: (Promise<Record<string, number | string>> | Promise<Record<string, number>>)[] = [];
      const listOfLayerIdsToLoad = weatherPopupLayerListHourly;
      if (selectedRasterLayer && listOfLayerIdsToLoad.indexOf(selectedRasterLayer.id) === -1)
        listOfLayerIdsToLoad.push(selectedRasterLayer.id);

      const layersToQuery = layers.object
        ? (listOfLayerIdsToLoad
            .map((id) => LayerManager.findLayer(id, layers.object))
            .filter((x) => x != null) as LayerManager.Layer[])
        : [];

      layersToQuery.forEach((l) => {
        const layerSelectedTime: Date | undefined = getTimeSliderCurrentDate(l.id);
        if (l.id in LayerManager.vectorLayersToLoad) {
          layerPromises.push(
            WmsManager.queryVectorLayerWMSValues(
              [l],
              LayerManager.vectorLayersToLoad[l.id].featureName,
              coords,
              auth.object,
              layerSelectedTime,
              l.lastUpdated != null ? new Date(l.lastUpdated) : undefined,
            ),
          );
        } else {
          layerPromises.push(
            WmsManager.queryWMSValues(
              [l],
              coords,
              auth.object,
              layerSelectedTime,
              l.lastUpdated != null ? new Date(l.lastUpdated) : undefined,
            ),
          );
        }
      });

      const allLayerData = await Promise.all([meteogramsPromise, ...layerPromises]);
      const [meteogramRes, ...layerDataRes] = allLayerData;
      const meteogramResults: Meteograms | null = queryMeteograms
        ? (meteogramRes as Meteograms)
        : meteogramsPromiseResults;

      // cache the results
      if (queryMeteograms) setMeteogramsPromiseResults(meteogramResults);

      // Process results
      if (identifyIncidentsResults.length > 0) {
        setOpenOverlay(ApplicationManager.Overlays.Incident);
      } else if (identifyWeatherStationsResults.length > 0) {
        setselectedWeatherStation(identifyWeatherStationsResults[0].id);
        setOpenOverlay(ApplicationManager.Overlays.WeatherStation);
      } else {
        const details: Record<string, number | string> = getWeatherPopupDetails(
          meteogramResults, // daily meteogram data
          layerDataRes as (Record<string, string | number> | Record<string, number>)[], // other layer data
          identifyFireWeatherAreaResults,
        );
        setselectedWeatherStation(null);
        setClickDetails(details);
        setOpenOverlay(ApplicationManager.Overlays.Weather);
      }
    };

  const hideIncidentOverlay = () => {
    setOpenOverlay(ApplicationManager.Overlays.None);
    mapDispatch?.dispatch(new AddMarkers([], undefined, styleMarker));
    dispatch(LayerActions.setPinnedLocation(null));
    setGoToIncident('');
  };

  const refreshIncidents = () => incidentsLayerCommand?.refresh();

  const tabs = {
    [ApplicationManager.Tabs.AllLayers]: {
      width: drawerOuterWidthValue,
      html: (
        <LayerList
          title="All Layers"
          columns={1}
          filterFn={(l) => !l.isHidden && (!l.isPrivileged || (l.isPrivileged && hasGroup('fdv-elevated', auth)))}
          displayMode="accordion"
        />
      ),
    },
    [ApplicationManager.Tabs.Bookmarks]: {
      width: drawerOuterWidthValue,
      html: (
        <div className={classes.bookmarkArea}>
          <Bookmarks getBounds={getBounds} mapDispatch={mapDispatch} />
        </div>
      ),
    },
    [ApplicationManager.Tabs.Incidents]: {
      width: drawerOuterWidthValue,
      html: (
        <IncidentsManager
          onRefresh={refreshIncidents}
          goToIncident={goToIncident}
          onZoomToIncident={(incident) => mapDispatch?.dispatch(new ZoomToBounds(incident.bbox, { maxZoom: 16 }))}
        />
      ),
    },
    [ApplicationManager.Tabs.Export]: {
      width: drawerOuterWidthValue,
      html: <ExportManager mapRef={mapRef} selectedDate={selectedTime} />,
    },
  };

  const updateOuterDrawer = (value: boolean, width?: number) => {
    setDrawerOuterOpen(value);
    if (width) setDrawerOuterWidth(width);

    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
      setTimeout(() => mapDispatch.dispatch(new PanOverlay(openOverlay)), 100);
    }
  };
  const [tabId, setTabId] = useState<keyof typeof tabs | null>(null);

  const loadTab = (id: typeof tabId) => {
    if (tabId === id) {
      setTabId(null);
      updateOuterDrawer(false);
    } else {
      setTabId(id);
      updateOuterDrawer(id !== null, id ? tabs[id].width : undefined);
    }
  };

  const handleMapRegistration = (mapDispatcher: (command: MapCommand) => void) => {
    setMapDispatch({ dispatch: mapDispatcher });
    mapDispatcher(new AddScaleLine());
    mapDispatcher(new MouseCoords());

    const mc = new MouseClick(makeOnMouseClick(mapDispatcher));
    setMouseClick(mc);
    mapDispatcher(mc);

    const rasterLayer = new AddWmsTileLayer({ layerName: null, auth: auth.object, opacity: initOpacity });
    setRasterLayerCommand(rasterLayer);
    mapDispatcher(rasterLayer);

    const childRasterLayer = new AddWmsTileLayer({ layerName: null, auth: auth.object, opacity: initOpacity });
    setChildLayerCommand(childRasterLayer);
    mapDispatcher(childRasterLayer);

    /**
     * Register the boundary label layer, associated with the underlying boundary layer
     * This is a vector layer and the label styles are controlled in the AddWmsMvtLayer class
     */
    const boundaryLabelLayer = new AddWmsMvtLayer({
      layerName: null,
      labelField: null,
      auth: auth.object,
      isLabelOn: false,
    });
    setBoundaryLabelLayerCommand(boundaryLabelLayer);
    mapDispatcher(boundaryLabelLayer);

    /**
     * Always set the isLabelOn to false on layer creation and never change it afterwards
     */
    const boundaryLayer = new AddWmsMvtLayer({
      layerName: null,
      labelField: null,
      auth: auth.object,
      isLabelOn: false,
    });
    setBoundaryLayerCommand(boundaryLayer);
    mapDispatcher(boundaryLayer);

    const weatherStationLayer = new AddWmsTileLayer({
      layerName:
        selectedLayers && selectedLayers.indexOf('WeatherStations') > -1
          ? LayerManager.findLayer('WeatherStations', LayerManager.layerData)?.serviceName ?? null
          : null,
      auth: auth.object,
    });
    setWeatherStationsLayerCommand(weatherStationLayer);
    mapDispatcher(weatherStationLayer);

    const incidentsLayer = new AddWmsTileLayer({
      layerName:
        selectedLayers && selectedLayers.indexOf('Incidents') > -1
          ? LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName ?? null
          : null,
      auth: auth.object,
    });
    setIncidentsLayerCommand(incidentsLayer);
    mapDispatcher(incidentsLayer);

    setSelectedTab(ApplicationManager.Tabs.AllLayers);
    loadTab(ApplicationManager.Tabs.AllLayers);
  };

  const handleTimeChange = (date: Date | null | undefined, layerDates: Record<string, Date | null>) => {
    if (selectedRasterLayer?.hoursPerStep === 0) {
      setTimeSliderLayerDates({});
    } else if (selectedRasterLayer) {
      setTimeSliderLayerDates(layerDates);
    }

    if (selectedRasterLayer && layerDates[selectedRasterLayer.id]) {
      let layerDate = layerDates[selectedRasterLayer.id];

      if (offsetsMap !== undefined && layerDate !== null) {
        const offsetMs = offsetsMap[selectedRasterLayer.id] * (1000 * 60 * 60);
        layerDate = new Date(layerDate.getTime() - offsetMs);
      }

      rasterLayerCommand?.updateDate(
        layerDate,
        selectedRasterLayer.lastUpdated != null ? new Date(selectedRasterLayer.lastUpdated) : null,
      );

      if (childLayer) {
        childLayerCommand?.updateDate(
          layerDates[childLayer.id],
          childLayer.lastUpdated != null ? new Date(childLayer.lastUpdated) : null,
        );
      }
    } else if (selectedRasterLayer?.hoursPerStep === 0) {
      rasterLayerCommand?.updateDate(
        selectedRasterLayer.timeSteps?.[0],
        selectedRasterLayer.lastUpdated != null ? new Date(selectedRasterLayer.lastUpdated) : null,
      );

      if (childLayer?.hoursPerStep === 0) {
        childLayerCommand?.updateDate(
          childLayer.timeSteps?.[0],
          childLayer.lastUpdated != null ? new Date(childLayer.lastUpdated) : null,
        );
      }
    }
  };

  const onOpacityUpdate = (value: number) => {
    rasterLayerCommand?.setOpacity(value);

    if (childLayer) childLayerCommand?.setOpacity(value);
  };

  const onLabelStateUpdate = (value: boolean) => boundaryLabelLayerCommand?.setLabelState(value);

  useEffect(() => {
    if (auth.status === 'finished' && fuelTypeModels.bom.status === 'idle')
      dispatch(FuelTypeModelActions.getBomFuelTypeModelsAll());
  }, [auth.status]);

  const updateTimeSlider = (value: boolean) => {
    setTimeSliderOpen(value);

    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
      setTimeout(() => mapDispatch.dispatch(new PanOverlay(openOverlay)), 100);
    }
  };

  //effect for displaying of popups
  useEffect(() => {
    if (mapDispatch) {
      mapDispatch.dispatch(new HideOverlay(openOverlay));
      mapDispatch.dispatch(new HideOverlay(prevOverlay));

      switch (openOverlay) {
        case ApplicationManager.Overlays.None:
          if (pinnedLocation && !panelOpen) {
            // reopen popup if details panel closed
            makeOnMouseClick(mapDispatch.dispatch)(pinnedLocation);
          }
          break;
        case ApplicationManager.Overlays.Weather:
          // close the weather popup when meteograms open AF-1192
          if (pinnedLocation && panelOpen) {
            setOpenOverlay(ApplicationManager.Overlays.None);
          } else if (pinnedLocation && !panelOpen) {
            mapDispatch?.dispatch(new ShowOverlay(openOverlay, pinnedLocation));
            setTimeout(() => mapDispatch.dispatch(new PanOverlay(openOverlay)), 100);
          }
          break;
        case ApplicationManager.Overlays.Incident:
          if (pinnedLocation) {
            updateTimeSlider(timeSliderOpen);
            mapDispatch?.dispatch(new ShowOverlay(openOverlay, pinnedLocation));
            setTimeout(() => mapDispatch.dispatch(new PanOverlay(openOverlay)), 100);
          }
          break;
        case ApplicationManager.Overlays.WeatherStation:
          if (pinnedLocation && panelOpen) {
            // AF-1192 hide weather station close popup when panel open
            setOpenOverlay(ApplicationManager.Overlays.None);
          } else if (pinnedLocation && !panelOpen) {
            updateTimeSlider(timeSliderOpen);
            mapDispatch?.dispatch(new ShowOverlay(openOverlay, pinnedLocation));
            setTimeout(() => mapDispatch.dispatch(new PanOverlay(openOverlay)), 100);
          }
          break;
        case ApplicationManager.Overlays.Loading:
          if (pinnedLocation) {
            mapDispatch?.dispatch(new ShowOverlay(openOverlay, pinnedLocation));
          }
          break;
        case ApplicationManager.Overlays.None:
        default:
          // unknown overlay or no overlay
          mapDispatch?.dispatch(new HideOverlay(ApplicationManager.Overlays.Weather));
          mapDispatch?.dispatch(new HideOverlay(ApplicationManager.Overlays.WeatherStation));
          mapDispatch?.dispatch(new HideOverlay(ApplicationManager.Overlays.Incident));
          mapDispatch?.dispatch(new HideOverlay(ApplicationManager.Overlays.Loading));
          break;
      }
    }
  }, [panelOpen, openOverlay, pinnedLocation, clickDetails]);

  useEffect(() => {
    if (mouseClick && mapDispatch) {
      // Use timeout with a delay to prevent unnecessary triggers since multiple state changes
      const mouseClickEventTimeout = setTimeout(() => {
        const reRun: boolean = openOverlay == ApplicationManager.Overlays.Weather;
        mouseClick.update(makeOnMouseClick(mapDispatch.dispatch, reRun));
        if (reRun) {
          if (mouseClick.isEventValid()) {
            mouseClick.rerunLastClick();
          } else if (pinnedLocation) {
            makeOnMouseClick(mapDispatch.dispatch)(pinnedLocation);
          }
        }
      }, 100);
      return () => clearTimeout(mouseClickEventTimeout);
    }
  }, [selectedTime, timeSliderLayerDates, layers.status, selectedLayers, selectedRasterLayer, auth.object]);

  /** Zoom to saved extent on map and setMapBounds
   * on initial load once the map is registered and layers are loaded */
  useEffect(() => {
    if (sentInitialLoad && mapDispatch) {
      const jd = getUserState(auth.object);
      if (jd) {
        const initialBounds = viewBounds || config.jurisdictionBounds[jd];
        const zoomOptions = { duration: 0, padding: viewBounds ? [0, 0, 0, 0] : [50, 50, 50, 50] };
        mapDispatch.dispatch(new ZoomToBounds(initialBounds, zoomOptions));
      }

      // setMapBounds here instead of in the map registration, because initialBounds have to be set first
      // otherwise initialBounds are overwritten by the Australia-wide map extent
      const mb = new GetMapBounds((bounds) => dispatch(LayerActions.setViewBounds(bounds)));
      setMapBounds(mb);
      mapDispatch.dispatch(mb);
    }
  }, [mapDispatch, sentInitialLoad]);

  useEffect(() => {
    if (layers.status === 'idle' && auth.status === 'finished') dispatch(LayerActions.getLayers());

    if (!sentInitialLoad && layers.status === 'finished' && auth.status === 'finished') {
      selectedLayers?.forEach((id) => {
        const l = LayerManager.findLayer(id, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      });

      if (selectedRasterLayer) dispatch(AnalyticsActions.postLayerView(selectedRasterLayer));

      if (childLayer) dispatch(AnalyticsActions.postLayerView(childLayer));

      if (selectedBoundary) {
        const l = LayerManager.findLayer(selectedBoundary, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      }

      if (selectedBaseMap) {
        const l = LayerManager.findLayer(selectedBaseMap, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      }

      setSentInitialLoad(true);
    }

    // regularly update layers every 5 minutes
    const interval = setInterval(() => {
      if ((layers.status === 'idle' || layers.status === 'finished') && auth.status === 'finished')
        dispatch(LayerActions.getLayers());
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, layers.status, auth.status]);

  /**
   * Follow on useEffect from the one that sets mapBounds.
   * Shows the pinned location on initial load.
   * This requires mapBounds set in local state first
   */
  useEffect(() => {
    if (mapBounds && pinnedLocation && mapDispatch) {
      makeOnMouseClick(mapDispatch.dispatch)(pinnedLocation);
    }
  }, [mapBounds]);

  /** Update both boundary layer and its label layer when boundary layer changes */
  useEffect(() => {
    const selectedBoundaryLayer = layers.object?.find((l) => l.id === selectedBoundary);
    // Update the labels of the associated boundary label layer
    boundaryLabelLayerCommand?.update({
      layerName: selectedBoundaryLayer?.labelLayerServiceName ? selectedBoundaryLayer.labelLayerServiceName : null,
      labelField: selectedBoundaryLayer?.labelLayerLabelField ? selectedBoundaryLayer.labelLayerLabelField : null,
      auth: auth.object,
    });

    /**
     * Update the boundary layer
     * MvtLabelField still needs to be set here, although for the boundary layers, this label is never shown.
     * In FSE, there's is a highlight feature which compares the selected area name with the label names from the boundary layers and highlights the boundary of that particular area. It's essential to have this label enabled and served via AddWmsMvtLayer
     */
    boundaryLayerCommand?.update({
      layerName: selectedBoundaryLayer != null ? selectedBoundaryLayer.serviceName : null,
      labelField: selectedBoundaryLayer != null ? selectedBoundaryLayer.mvtLabelField : null,
      auth: auth.object,
    });
  }, [selectedBoundary, boundaryLayerCommand, layers.object, auth.object]);

  useEffect(() => {
    // Use the current timeslider time to initialize or if that is null, then the first timestep
    // named latest start to match with the compare tool
    const latestStart = LayerManager.getLatestLayerStartDate([selectedRasterLayer]);
    const selectedLayer = layers.object?.find((l) => l.id === selectedMainLayer);

    // If there is a selected layer, get the most relevant time step
    let stepTime = null;
    if (selectedLayer?.id) {
      stepTime = LayerManager.getStepTime(layers.object || undefined, selectedLayer?.id, selectedTime ?? latestStart);
    }

    rasterLayerCommand?.update({
      layerName: selectedLayer != null ? selectedLayer.serviceName : null,
      auth: auth.object,
      date: stepTime != null ? stepTime : null,
    });

    if (selectedLayer?.childLayer) {
      const selectedChildLayer = layers.object?.find((l) => l.id === selectedLayer.childLayer);
      childLayerCommand?.update({
        layerName: selectedChildLayer != null ? selectedChildLayer.serviceName : null,
        auth: auth.object,
        date: stepTime != null ? stepTime : null,
      });
    } else {
      childLayerCommand?.update({
        layerName: null,
        auth: auth.object,
      });
    }
    if (selectedLayer?.hoursPerStep === 0) {
      handleTimeChange(null, {} as Record<string, Date | null>);
    }
  }, [selectedMainLayer, rasterLayerCommand, childLayerCommand, layers.object, auth.object]);

  useEffect(() => {
    if (selectedLayers && selectedLayers.indexOf('Incidents') > -1) {
      incidentsLayerCommand?.update({
        layerName: LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName ?? null,
        auth: auth.object,
      });
    } else {
      incidentsLayerCommand?.update({
        layerName: null,
        auth: auth.object,
      });
    }

    if (selectedLayers && selectedLayers.indexOf('WeatherStations') > -1) {
      weatherStationsLayerCommand?.update({
        layerName: LayerManager.findLayer('WeatherStations', LayerManager.layerData)?.serviceName ?? null,
        auth: auth.object,
      });
    } else {
      weatherStationsLayerCommand?.update({
        layerName: null,
        auth: auth.object,
      });
    }
  }, [selectedLayers, auth.object]);

  useEffect(() => {
    // will update incidents every 60 seconds
    const interval = setInterval(() => refreshIncidents(), 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentsLayerCommand]);

  const updatePanel = () => {
    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
      setTimeout(() => mapDispatch.dispatch(new PanOverlay(openOverlay)), 100);
    }
  };

  return (
    <>
      {/* merge into a single drawer AF-1191 */}
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOuterOpen}
        onClose={() => updateOuterDrawer(false)}
        className={`${classes.drawer} ${classes.content} ${classes.drawerTransition}`}
        style={{
          width: drawerOuterWidth,
        }}
        PaperProps={{
          style: {
            width: drawerOuterWidth,
            left: 0,
          },
        }}
        classes={{
          paper: `${classes.drawerTransition}}`,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContent}>
          <div className={classes.drawerContent}>{tabId != null && tabs[tabId].html}</div>
          <Divider />
          <div className={classes.lonlat}>
            <TextField
              value={goToLongitude}
              type="number"
              size="small"
              onChange={(event: any) => setGoToLongitude(event?.target?.value)}
              variant="outlined"
              placeholder="Longitude"
              InputProps={{
                inputProps: {
                  style: {
                    padding: theme.spacing(0.5),
                  },
                },
              }}
            />
            <TextField
              value={goToLatitude}
              type="number"
              size="small"
              onChange={(event: any) => setGoToLatitude(event?.target?.value)}
              variant="outlined"
              placeholder="Latitude"
              InputProps={{
                inputProps: {
                  style: {
                    padding: theme.spacing(0.5),
                  },
                },
              }}
            />
            <LocationSearching style={{ cursor: 'pointer' }} onClick={goToLocation} />
          </div>

          <ToggleButtonGroup
            value={selectedTab}
            orientation="vertical"
            exclusive
            onChange={(_, value) => {
              if (value) {
                setSelectedTab(value);
                loadTab(value);
              }
            }}
            aria-label=""
          >
            <ToggleButton
              key={ApplicationManager.Tabs.AllLayers}
              value={ApplicationManager.Tabs.AllLayers}
              className={classes.sidebarBtn}
            >
              All Layers
            </ToggleButton>

            <ToggleButton
              key={ApplicationManager.Tabs.Incidents}
              value={ApplicationManager.Tabs.Incidents}
              className={classes.sidebarBtn}
            >
              Incidents
            </ToggleButton>

            <ToggleButton
              key={ApplicationManager.Tabs.Bookmarks}
              value={ApplicationManager.Tabs.Bookmarks}
              className={classes.sidebarBtn}
            >
              Bookmarks
            </ToggleButton>

            <ToggleButton
              key={ApplicationManager.Tabs.Export}
              value={ApplicationManager.Tabs.Export}
              className={classes.sidebarBtn}
            >
              Export
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Drawer>
      <div
        className={`${classes.map}`}
        style={{
          width: drawerOuterOpen ? `calc(100% - ${drawerOuterWidth})` : '100%',
          marginLeft: drawerOuterOpen ? mapOffset : '0',
        }}
      >
        <div style={{ display: 'grid', height: '100%', gridTemplateRows: '1fr auto auto' }}>
          <div style={{ flexGrow: 1, position: 'relative' }}>
            <Map
              mapRef={mapRef}
              basemap={selectedBaseMap}
              shouldDisplay
              registerMapCommand={handleMapRegistration}
              customOverlays={[
                {
                  id: ApplicationManager.Overlays.Loading,
                  jsx: (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        transform: 'translate(-5px, -5px)',
                        backgroundColor: 'transparent',
                        display: 'grid',
                      }}
                    >
                      <CircularProgress style={{ color: 'black' }} size="10" aria-valuetext="loading" />
                    </div>
                  ),
                },
                {
                  id: ApplicationManager.Overlays.Incident,
                  autoPan: true,
                  jsx: (
                    <IncidentPopup
                      close={() => {
                        hideIncidentOverlay();
                      }}
                      incidents={incidents.identify}
                      onViewDetails={(i: any) => {
                        if (tabId !== ApplicationManager.Tabs.Incidents) {
                          setSelectedTab(ApplicationManager.Tabs.Incidents);
                          loadTab(ApplicationManager.Tabs.Incidents);
                        }

                        // Id on it's own doesn't seem to work
                        setGoToIncident(i.title ?? i.id);
                      }}
                    />
                  ),
                },
                {
                  id: ApplicationManager.Overlays.WeatherStation,
                  autoPan: true,
                  jsx: (
                    <WeatherStationPopup
                      close={() => {
                        setOpenOverlay(ApplicationManager.Overlays.None);
                        dispatch(LayerActions.setPinnedLocation(null));
                        mapDispatch?.dispatch(new AddMarkers([], undefined, styleMarker));
                      }}
                      weatherStations={meteograms.identify}
                      onViewDetails={(weatherStation) => {
                        setselectedWeatherStation(weatherStation.id);
                        setTimeSliderOpen(false);
                        setPanelOpen(true);
                      }}
                    />
                  ),
                },
                {
                  id: ApplicationManager.Overlays.Weather,
                  autoPan: true,
                  jsx: (
                    <WeatherPopup
                      close={() => {
                        setOpenOverlay(ApplicationManager.Overlays.None);
                        mapDispatch?.dispatch(new AddMarkers([], undefined, styleMarker));
                        dispatch(LayerActions.setPinnedLocation(null));
                      }}
                      onViewDetails={() => {
                        setTimeSliderOpen(false);
                        setPanelOpen(true);
                        // setting this to empty because if the user closes
                        // the panel again, they want the popup to reopen
                        // and this will not happen if the key is unchanged
                        setLastExecutedKey('');

                        // close overlay when panel is open per AF-1192
                        setOpenOverlay(ApplicationManager.Overlays.None);
                      }}
                      selectedDate={selectedTime}
                      selectedLayer={selectedRasterLayer}
                      coords={pinnedLocation}
                      data={clickDetails}
                    />
                  ),
                },
              ]}
            >
              <FloatingMapButton
                left="65px"
                top="20px"
                width="120px"
                height="30px"
                className={classes.compareFAB}
                buttonStyle={{
                  color: theme.palette.common.neutralDark,
                }}
                onClick={async () => {
                  await dispatch(LayerActions.setViewBounds(getBounds()));
                  navigate('/compare');
                }}
                muiButton
              >
                <Compare /> Compare
              </FloatingMapButton>

              <MapOverlay
                top="20px"
                left="205px"
                opacity={1.0}
                style={{
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
                }}
                className={classes.layerControls}
              >
                <LayerControls
                  buttonStyle={{
                    width: '165px',
                    height: '30px',
                    borderRadius: '120px',
                  }}
                  onOpacityUpdate={onOpacityUpdate}
                  initOpacity={initOpacity}
                  initLabelState={boundaryLabelLayerCommand?.getLabelState()}
                  onLabelStateUpdate={onLabelStateUpdate}
                  isLabelToggleDisabled={selectedBoundary ? !layersWithToggleableLabels.has(selectedBoundary) : true}
                />
              </MapOverlay>

              <FloatingMapButton
                ariaLabel="toggle mapview config"
                onClick={() => updateOuterDrawer(!drawerOuterOpen)}
                style={{ borderRadius: '0 4px 4px 0' }}
                buttonStyle={{ flexDirection: 'column', justifyContent: 'stretch', backgroundColor: 'black' }}
                width="25px"
                height="150px"
                top="calc(50% - 75px)"
                left="0%"
                className={`${classes.content} ${classes.drawerTransition}`}
              >
                {drawerOuterOpen ? (
                  <>
                    <ChevronLeft className={classes.sidebarfabicon} />
                    <div className={classes.sidebarfab}>Hide Control Panel</div>
                  </>
                ) : (
                  <>
                    <ChevronRight className={classes.sidebarfabicon} />
                    <div className={classes.sidebarfab}>Show Control Panel</div>
                  </>
                )}
              </FloatingMapButton>
              <FloatingMapButton
                ariaLabel="toggle time slider draw"
                onClick={() => updateTimeSlider(!timeSliderOpen)}
                style={{ borderRadius: '0 4px 4px 0' }}
                buttonStyle={{ flexDirection: 'row', backgroundColor: 'black' }}
                width={timeSliderOpen ? '25px' : '250px'}
                height="25px"
                top="calc(100% - 25px)"
                left={`calc(50% - ${timeSliderOpen ? '12.5px' : '125px'})`}
                className={`${classes.content} ${classes.drawerTransition}`}
              >
                {timeSliderOpen ? (
                  <KeyboardArrowDown className={classes.sidebarfabicon} />
                ) : (
                  <>
                    <KeyboardArrowUp className={classes.sidebarfabicon} />
                    <div className={classes.timesliderfab}>
                      <span style={{ paddingRight: 4, backgroundColor: 'black', color: 'white' }}>Show timeslider</span>
                      <span
                        style={{
                          paddingLeft: 4,
                          borderLeft: `solid 1px ${theme.palette.common.neutralLight}`,
                          backgroundColor: 'black',
                          color: 'white',
                        }}
                      >
                        {selectedTime
                          ?.toLocaleDateString('en-AU', {
                            weekday: 'short',
                            day: '2-digit',
                            month: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            // @ts-ignore
                            hourCycle: 'h23',
                          })
                          .replace(/,/g, '') ?? ''}
                      </span>
                    </div>
                  </>
                )}
              </FloatingMapButton>
              {selectedRasterLayer && (
                <MapOverlay
                  left="default"
                  opacity={1.0}
                  top="20px"
                  style={{
                    right: '20px',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
                  }}
                >
                  <Legend layer={selectedRasterLayer} canBeOpen={!panelOpen} />
                </MapOverlay>
              )}
            </Map>
          </div>
          <div
            className={classes.panel}
            style={{ position: 'relative', top: timeSliderOpen ? 'auto' : '100%', height: timeSliderOpen ? 'auto' : 0 }}
          >
            <Timeslider layers={selectedRasterLayerList} onTimeChange={handleTimeChange} offsetsMap={offsetsMap} />
          </div>
          <div
            className={classes.panel}
            style={{ position: 'relative', top: panelOpen ? 'auto' : '100%', height: panelOpen ? 'auto' : 0 }}
          >
            <DetailsPanel
              isOpen={panelOpen}
              onClose={() => {
                setPanelOpen(false);
                updatePanel();
              }}
              onResize={() => updatePanel()}
              coords={pinnedLocation || prevPinnedLocation || null} // prevPinnedLocation preserves the details panel if popup is closed
              selectedDate={selectedTime ?? null}
              identifyData={clickDetails}
              weatherStationId={selectedWeatherStation ?? undefined}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FDVViewer;
