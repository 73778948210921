import React, { useState, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Card, CardContent, Divider, Button, Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import OpacitySlider from './OpacitySlider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 120,
    maxWidth: 340,
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
    border: '0px',
  },
  buttonWrapper: {
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
  },
  button: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.neutralDark,
    backgroundColor: theme.palette.common.white,
    border: '0px',
    '&:focus': {
      outline: '0px',
    },
    cursor: 'pointer',
  },
  content: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  labelToggleSection: {
    display: 'flex',
    'justify-content': 'space-between',
    'align-items': 'center',
  },
  label: {
    marginLeft: -5,
    fontSize: 16,
    color: theme.palette.common.neutralDark,
    fontWeight: 'bold',
  },
  switchLabel: {
    fontSize: 16,
    color: theme.palette.common.neutralDark,
    fontWeight: 'bold',
  },
  divider: {
    marginLeft: -5,
    marginRight: -5,
    marginTop: 10,
  },
  topDivider: {
    marginLeft: -5,
    marginRight: -5,
    marginTop: 4,
  },
}));

interface Props extends PropsWithChildren {
  ariaLabel?: string | undefined;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  onOpacityUpdate?: (value: number) => void;
  initOpacity?: number;
  initLabelState?: boolean;
  onLabelStateUpdate?: (value: boolean) => void;
  isLabelToggleDisabled?: boolean;
}

/**
 * Main design decision: all controlled states should be stored in this component, rather than put it somewhere else
 * This is due to multi-view pages.
 * Each of the page has one LayerControls component instance and each should have its own independent state.
 * Elevating the states or pushing down the states are sub-optimal choices to achieve the above requirement.
 */
const LayerControls: React.FunctionComponent<Props> = ({
  ariaLabel = undefined,
  style = {},
  buttonStyle,
  onOpacityUpdate,
  initOpacity,
  initLabelState,
  onLabelStateUpdate,
  isLabelToggleDisabled = true,
}) => {
  const classes = useStyles();

  // State to control the open/close of the LayerControls card
  const [openValue, setOpen] = useState<boolean>(false);

  /**
   * State to control if the label switch is on/off
   * This state is stored in this component and shouldn't be elevated, because in MapCompare view, each view has its own states (i.e. independent LayerControls component)
   * */
  const [isLabelOn, setIsLabelOn] = useState<boolean>(initLabelState ?? false);
  // Wrapper function to control the label on/off and refresh the layer
  const onLabelToggleChange = () => {
    const prevState = isLabelOn;
    setIsLabelOn(!prevState);
    if (onLabelStateUpdate) onLabelStateUpdate(!prevState);
  };

  /**
   * State to control layer opacity value on each OpacitySlider rendering
   * It can be achieved using state hoisting, but we don't want to change the OpacitySlider which manages the opacity value in itself
   */
  const [opacitySliderValue, setOpacitySliderValue] = useState(initOpacity ?? 100);
  // Wrapper function to update the parent component state when the child component state changes
  const onOpacitySliderChange = (value: number) => {
    setOpacitySliderValue(value);
    if (onOpacityUpdate) onOpacityUpdate(value);
  };

  return (
    <Card className={classes.root} style={style}>
      <div className={classes.buttonWrapper} style={buttonStyle}>
        <Button
          aria-label={ariaLabel}
          className={classes.button}
          style={buttonStyle}
          type="button"
          onClick={() => setOpen(!openValue)}
        >
          <Settings /> LAYER CONTROLS
        </Button>
      </div>
      {openValue && (
        <>
          <Divider className={classes.topDivider} />
          <CardContent className={classes.content}>
            <section>
              <div className={classes.label}>Opacity ({opacitySliderValue}%)</div>
              <OpacitySlider onChange={onOpacitySliderChange} initialValue={opacitySliderValue} />
            </section>
            <Divider className={classes.divider} />
            <section className={classes.labelToggleSection}>
              <div className={classes.label}>Label</div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isLabelOn}
                      onChange={onLabelToggleChange}
                      disabled={isLabelToggleDisabled}
                      inputProps={{ 'aria-label': 'controlled switch' }}
                    />
                  }
                  label={isLabelToggleDisabled ? 'Disabled' : isLabelOn ? 'ON' : 'OFF'}
                  labelPlacement="start"
                  classes={{ label: classes.switchLabel }}
                />
              </FormGroup>
            </section>
          </CardContent>
        </>
      )}
    </Card>
  );
};

LayerControls.displayName = 'LayerControls';
export default LayerControls;
